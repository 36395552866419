//todo: work on gif image in menu (video)


// e4383b
// 191919

const data = [
    {
        id: '1',
        postType: 'first',
        type: 'Video',
        type2: 'Live session',
        lenght: '16:39',
        title: 'TIMEA',
        subtitle: 'TIMEA',
        src: "https://player.vimeo.com/video/1016123302",
        video: '/assets/videos/timea.mp4',
        image: '/assets/images/timea/_4.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: 'Natáčanie videoklipov alebo live sessions je pre nás srdcová záležitosť. Na chvíľu sa cítite súčasťou kapely, spoznáte ich fungovanie a na krátky okamih žijete ich život. Každá kapela alebo jednotlivec prináša prostredníctvom hudby a osobnosti do videa inú energiu. Timea a jej ľahko počúvateľný pop s emotívnou atmosférou a inšpirácií z alternatívnejších žánrov stihol za krátku dobu zaujať širokú verejnosť. My sme mali možnosť live spracovať jej tri piesne v priestore s príznačným názvom Oáza v Starej Tržnici v Bratislave.',
        client: 'TIMEA',
        color: 'fff',
        client_people: [{ title: 'Vocals', person: 'Timea' },
        { title: 'Bass', person: 'Michal Šelep' },
        { title: 'Drums', person: 'Mattia Muller' },
        { title: 'Guitar', person: 'Lukáš Hlaváč' },
        { title: 'Drums', person: 'Peter Kunzo' }],

        production_people: [{ title: 'Camera one', person: 'Jakub Brehuv' },
        { title: 'Camera two', person: 'Šimon Brehuv' },
        { title: 'Edit', person: 'Šimon Brehuv' },
        { title: 'Colour grading', person: 'Jakub Brehuv' }],

        pictures: [
            '/assets/images/timea/_4.jpg',
            '/assets/images/timea/_10.jpg',
            '/assets/images/timea/_5.jpg',
            '/assets/images/timea/_1.jpg',
            '/assets/images/timea/_2.jpg',
            '/assets/images/timea/_3.jpg',
            '/assets/images/timea/_8.jpg',
            '/assets/images/timea/_9.jpg',
            '/assets/images/timea/_7.jpg',
            '/assets/images/timea/_11.jpg',
            '/assets/images/timea/_12.jpg',
            '/assets/images/timea/_13.jpg',
            '/assets/images/timea/_14.jpg',
            '/assets/images/timea/_15.jpg',
            '/assets/images/timea/_16.jpg',
            '/assets/images/timea/_17.jpg',
            '/assets/images/timea/_18.jpg',
            '/assets/images/timea/_19.jpg',
            '/assets/images/timea/_20.jpg',
            '/assets/images/timea/_21.jpg',
            '/assets/images/timea/_22.jpg',
            '/assets/images/timea/_6.jpg',
        ]
    },
    {
        id: '2',
        postType: '1',
        type: 'Video',
        type2: 'music clip',
        lenght: '4:19',
        title: 'Z dvoch dlaňí',
        subtitle: 'Sima Magušinová',
        src: "https://player.vimeo.com/video/1016141503",
        video: '/assets/videos/sima.mp4',
        image: '/assets/images/sima/16.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: 'Pieseň o radosti a slobode, ktorú prežívam keď veci odovzdám do Božích rúk :-)',
        client: 'Sima Magusinova',
        link: 'https://drive.google.com/drive/folders/1e4_xA2mw4z5Cte80nAqJgYp8_8q3LzD7?usp=sharing',
        color: 'fff',

        client_people: [{ title: 'Hudba, text', person: 'Sima Magušinová' },
        { title: 'Klavír', person: 'Daniel Špiner' },
        { title: 'Kontrabas', person: 'Michal Šelep' },
        { title: 'Bicie', person: 'Igor Ajdži Sabo' },
        { title: 'Aranž pre dychovú sekciu', person: ' Adrián Harvan' },
        { title: 'Dychová sekcia', person: 'Andrej Rázga, Marek Pastírik, Martin Noga' },
        { title: 'Mix, master', person: 'Randy Gnepa' },
        { title: 'Nahraté v', person: 'Melody studio' },
    ],
        production_people: [{ title: 'Camera', person: 'Jakub Brehuv' },
        { title: 'Edit', person: 'Šimon Brehuv' },
        { title: 'Colour grading', person: 'Jakub Brehuv' }],
        pictures: [
            '/assets/images/sima/4.jpg',
            '/assets/images/sima/10.jpg',
            '/assets/images/sima/5.jpg',
            '/assets/images/sima/29.jpg',
            '/assets/images/sima/28.jpg',
            '/assets/images/sima/2.jpg',
            '/assets/images/sima/3.jpg',
            '/assets/images/sima/8.jpg',
            '/assets/images/sima/9.jpg',
            '/assets/images/sima/7.jpg',
            '/assets/images/sima/11.jpg',
            '/assets/images/sima/12.jpg',
            '/assets/images/sima/13.jpg',
            '/assets/images/sima/14.jpg',
            '/assets/images/sima/15.jpg',
            '/assets/images/sima/16.jpg',
            '/assets/images/sima/17.jpg',
            '/assets/images/sima/18.jpg',
            '/assets/images/sima/19.jpg',
            '/assets/images/sima/20.jpg',
            '/assets/images/sima/22.jpg',
            '/assets/images/sima/6.jpg',
            '/assets/images/sima/24.jpg',
            '/assets/images/sima/25.jpg',
            '/assets/images/sima/26.jpg',
            '/assets/images/sima/27.jpg',

        ]
    },
    {
        id: '3',
        postType: '1',
        type: 'Video',
        type2: 'music clip',
        lenght: '3:20',
        title: 'Duše divoké',
        subtitle: 'Sima Magušinová',
        src: "https://player.vimeo.com/video/1017103928",
        video: '/assets/videos/sima2.mp4',
        image: '/assets/images/sima_web/_3.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: 'Pieseň o jednoduchosti, nadhľade a zdravej nespútanosti, ktorá nám prináša radosť - ako odmenu:-)',
        clientHead: 'Pieseň o jednoduchosti, nadhľade a zdravej nespútanosti, ktorá nám prináša radosť - ako odmenu:-)',
        client: 'Sima Magusinova',
        link: 'https://drive.google.com/drive/folders/1e4_xA2mw4z5Cte80nAqJgYp8_8q3LzD7?usp=sharing',
        color: 'fff',
        client_people: [{ title: 'Hudba, text', person: 'Sima Magušinová' },
        { title: 'Spev, vokály', person: 'Zuzana Smatanová, Sima Magušinová' },
        { title: 'Orchester', person: 'Symfonický orchester Slovenského rozhlasu' },
        { title: 'Dirigent', person: 'Adrián Kokoš' },
        { title: 'Aranž pre orchester', person: 'Adrián Harvan' },
        { title: 'Gitara', person: 'Martin Zajko' },
        { title: 'Perkusie', person: 'Igor Ajdži Sabo' },
        { title: 'Mix, master', person: 'Basem3nt Studio (Randy Gnepa)' },
        { title: 'Nahraté v', person: 'Melody studio' }],

        production_people: [{ title: 'Camera', person: 'Jakub Brehuv' },
        { title: 'Edit', person: 'Šimon Brehuv' },
        { title: 'Colour grading', person: 'Jakub Brehuv' },
        { title: 'Kostým', person: 'Dáša Kinik' },
        { title: 'Tanec', person: 'Ivana Antolová, Natália Benková, Alžbeta Talčíková, Tereza Kmotorková' },
        { title: 'Priestor', person: ' Kollégium Antona Neuwirtha' },
        { title: 'Tanec', person: 'Ivana Antolová, Natália Benková, Alžbeta Talčíková, Tereza Kmotorková' },],
        pictures: [
            '/assets/images/sima_web/_1.jpg',
            '/assets/images/sima_web/_2.jpg',
            '/assets/images/sima_web/_3.jpg',
            '/assets/images/sima_web/_4.jpg',
            '/assets/images/sima_web/_5.jpg',
            '/assets/images/sima_web/_6.jpg',
            '/assets/images/sima_web/_7.jpg',
            '/assets/images/sima_web/_8.jpg',
            '/assets/images/sima_web/_9.jpg',
            '/assets/images/sima_web/_10.jpg',
            '/assets/images/sima_web/_11.jpg',
            '/assets/images/sima_web/_12.jpg',
            '/assets/images/sima_web/_13.jpg',
            '/assets/images/sima_web/_14.jpg',
        ]
    },
    {
        id: '4',
        postType: '1',
        type: 'Video',
        type2: 'music clip',
        lenght: '3:22',
        title: 'Sunflower',
        subtitle: 'Tamara Kramar',
        src: "https://player.vimeo.com/video/1017063038",
        video: '/assets/videos/tamara.mp4',
        image: '/assets/images/tamara_web/_3.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: '',
        client: 'Tamara Kramar',
        color: 'fff',
     
        client_people: [{ title: ' Written, produced and performed', person: 'Tamara Kramar' },
        { title: 'Bass', person: 'Michal Šelep' },
        { title: 'Keys', person: 'Peter Kunzo' },
        { title: 'Flute', person: 'Ruta Sipola' },
        { title: 'Drums', person: 'Nicholas Stampf' },
        { title: 'Mix', person: 'Jaro Žigo' },
        { title: 'Master', person: 'Jan Zborovjan' }],

     
        production_people: [{ title: 'Video', person: 'CRAEHOUSE' },
        { title: 'bts/assistandst', person: 'Jakub Brehuv' }],
       
       
        pictures: [
            '/assets/images/tamara_web/_4.jpg',
            '/assets/images/tamara_web/_10.jpg',
            '/assets/images/tamara_web/_5.jpg',
            '/assets/images/tamara_web/_1.jpg',
            '/assets/images/tamara_web/_2.jpg',
            '/assets/images/tamara_web/_3.jpg',
            '/assets/images/tamara_web/_8.jpg',
            '/assets/images/tamara_web/_9.jpg',
            '/assets/images/tamara_web/_7.jpg',
            '/assets/images/tamara_web/_11.jpg',
            '/assets/images/tamara_web/_12.jpg',
            '/assets/images/tamara_web/_13.jpg',
            '/assets/images/tamara_web/_14.jpg',
            '/assets/images/tamara_web/_15.jpg',
            '/assets/images/tamara_web/_16.jpg',
            '/assets/images/tamara_web/_17.jpg',
            '/assets/images/tamara_web/_18.jpg',
            '/assets/images/tamara_web/_19.jpg',
            '/assets/images/tamara_web/_20.jpg',
            '/assets/images/tamara_web/_21.jpg',
            '/assets/images/tamara_web/_22.jpg',
            '/assets/images/tamara_web/_6.jpg',
        ]
    },
    {
        id: '5',
        postType: '1',
        type: 'Video',
        type2: 'music clip',
        lenght: '3:39',
        title: 'vlny bozky ',
        subtitle: 'Rijola',
        src: "https://player.vimeo.com/video/1017670681",
        video: '/assets/videos/rijola.mp4',
        image: '/assets/images/rijola/_3.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: '',
        client: 'Rijola',
        color: 'fff',
     
        // client_people: [{ title: ' Written, produced and performed', person: 'Tamara Kramar' },
        // { title: 'Bass', person: 'Michal Šelep' },
        // { title: 'Keys', person: 'Peter Kunzo' },
        // { title: 'Flute', person: 'Ruta Sipola' },
        // { title: 'Drums', person: 'Nicholas Stampf' },
        // { title: 'Mix', person: 'Jaro Žigo' },
        // { title: 'Master', person: 'Jan Zborovjan' }],
        client_people: [{ title: 'Bicie', person: 'David Motyl' },
            { title: 'Spev', person: 'Andrej Velebir' }
          ],

     
        production_people: [{ title: 'Camera', person: 'Jakub Brehuv' },
            { title: 'Edit', person: 'Šimon Brehuv' },
            { title: 'Colour grading', person: 'Jakub Brehuv' }],
       
       
        pictures: [
            '/assets/images/rijola/_3.jpg',
            '/assets/images/rijola/_4.jpg',
            '/assets/images/rijola/_5.jpg',
            '/assets/images/rijola/_6.jpg',
            '/assets/images/rijola/_7.jpg',
            '/assets/images/rijola/_8.jpg',
            '/assets/images/rijola/_9.jpg',
            '/assets/images/rijola/_10.jpg',
            '/assets/images/rijola/_11.jpg',
            '/assets/images/rijola/_12.jpg',
            '/assets/images/rijola/_13.jpg',
            '/assets/images/rijola/_14.jpg',
            '/assets/images/rijola/_15.jpg',
            '/assets/images/rijola/_16.jpg',
            '/assets/images/rijola/_17.jpg',
            '/assets/images/rijola/_18.jpg',
            '/assets/images/rijola/_19.jpg',
            '/assets/images/rijola/_20.jpg',
            '/assets/images/rijola/_1.jpg',
            '/assets/images/rijola/_2.jpg',


   
        ]
    },
    {
        id: '6',
        postType: '1',
        type: 'Video',
        type2: 'ad',
        lenght: '1:10',
        title: 'PESŤAHUJME SPOLU WAVE CNK',
        subtitle: 'WAVE',
        src: "https://player.vimeo.com/video/1018195989",
        video: '/assets/videos/wave.mp4',
        image: '/assets/images/wave/_3.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        info: "0",
        date: '2022-05-03',
        desc: '',
        client: 'WAVE',
        color: 'fff',
     
        // client_people: [{ title: ' Written, produced and performed', person: 'Tamara Kramar' },
        // { title: 'Bass', person: 'Michal Šelep' },
        // { title: 'Keys', person: 'Peter Kunzo' },
        // { title: 'Flute', person: 'Ruta Sipola' },
        // { title: 'Drums', person: 'Nicholas Stampf' },
        // { title: 'Mix', person: 'Jaro Žigo' },
        // { title: 'Master', person: 'Jan Zborovjan' }],
        client_people: [
          ],

     
          production_people: [{ title: 'Director', person: 'Alex Gburova' },
            { title: 'Camera, Edit, Colour grading', person: 'Jakub Brehuv' },
            { title: 'Sound', person: 'Šimon Brehuv' }],
       
       
        pictures: [
            '/assets/images/wave/_1.jpg',
            '/assets/images/wave/_2.jpg',
            '/assets/images/wave/_3.jpg',
            '/assets/images/wave/_4.jpg',
            '/assets/images/wave/_5.jpg',
            '/assets/images/wave/_6.jpg',
            '/assets/images/wave/_7.jpg',
            '/assets/images/wave/_8.jpg',
            '/assets/images/wave/_9.jpg',
            '/assets/images/wave/_10.jpg',
        ]
    },
    // {
    //     id: '5',
    //     postType: '1',
    //     type: 'Video',
    //     type2: 'music clip',
    //     lenght: '4:19',
    //     title: 'Z dvoch dlaňí',
    //     subtitle: 'SIMA',
    //     src: "https://player.vimeo.com/video/825051754?h=a80921aa65",
    //     image: '/assets/images/sima/30.jpg',
    //     video: '/assets/videos/wave.mp4',
    //     imageWidth: "1920",
    //     imageHeight: "1280",
    //     imagesCount: "97",
    //     info: "0",
    //     date: '2022-05-03',
    //     desc: '',
    //     clientHead: 'Ahojte, dalsie fotky najdete este tuna na linku :)',
    //     client: 'Sima Magusinova',
    //     link: 'https://drive.google.com/drive/folders/1e4_xA2mw4z5Cte80nAqJgYp8_8q3LzD7?usp=sharing',
    //     color: 'fff',
    //     client_people: [{ title: 'Vocals', person: 'Timea' },
    //     { title: 'Bass', person: 'Michal Šelep' },
    //     { title: 'Drums', person: 'Mattia Muller' },
    //     { title: 'Guitar', person: 'Lukáš Hlaváč' },
    //     { title: 'Drums', person: 'Peter Kunzo' }],
    //     production_people: [{ title: 'Camera one', person: 'Jakub Brehuv' },
    //     { title: 'Edit', person: 'Šimon Brehuv' },
    //     { title: 'Colour grading', person: 'Jakub Brehuv' }],
    //     pictures: [
    //         '/assets/images/tamara_web/_4.jpg',
    //         '/assets/images/tamara_web/_10.jpg',
    //         '/assets/images/tamara_web/_5.jpg',
    //         '/assets/images/tamara_web/_1.jpg',
    //         '/assets/images/tamara_web/_2.jpg',
    //         '/assets/images/tamara_web/_3.jpg',
    //         '/assets/images/tamara_web/_8.jpg',
    //         '/assets/images/tamara_web/_9.jpg',
    //         '/assets/images/tamara_web/_7.jpg',
    //         '/assets/images/tamara_web/_11.jpg',
    //         '/assets/images/tamara_web/_12.jpg',
    //         '/assets/images/tamara_web/_13.jpg',
    //         '/assets/images/tamara_web/_14.jpg',
    //         '/assets/images/tamara_web/_15.jpg',
    //         '/assets/images/tamara_web/_16.jpg',
    //         '/assets/images/tamara_web/_17.jpg',
    //         '/assets/images/tamara_web/_18.jpg',
    //         '/assets/images/tamara_web/_19.jpg',
    //         '/assets/images/tamara_web/_20.jpg',
    //         '/assets/images/tamara_web/_21.jpg',
    //         '/assets/images/tamara_web/_22.jpg',
    //         '/assets/images/tamara_web/_6.jpg',
    //     ]
    // },
];

export default data;