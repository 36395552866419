import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';



function evaluateGuess(size) {
  console.log(Number(size))
  console.log(Number(767))

  if (Number(size) <= Number(767) ) {
  console.log("false")
    return false;
  }else{
    console.log("true")
    return true;
  };
}


const Footer = ({ data }) => (
<footer className="Footer">
{ //Check if message failed
     (evaluateGuess(data) === true)
       ? <LazyLoadImage
       className="Svg"
       width="2560" height="1698"
       src="/assets/images/logo_white.svg"
       alt=""
     />
       : <LazyLoadImage
       className="Svg"
       width="2560" height="1698"
       src="/assets/images/logo.svg"
       alt=""
     /> 
  }
  {(evaluateGuess(data) === true)
  ?
  <div className="Footer-details">
  <p>( CONTACT )
    <br></br>
    <a href="mailto:hello@crae.house" rel="noopener">hello@crae.house</a> • <a href="https://www.instagram.com/craehouse/" rel="noopener">@craehouse</a>
  </p>
</div>

  :<div className="Footer-details-black">
  <p>( CONTACT )
    <br></br>
    <a href="mailto:hello@crae.house" rel="noopener">hello@crae.house</a> • <a href="https://www.instagram.com/craehouse/" rel="noopener">@craehouse</a>
  </p>
</div>}
{ //Check if message failed
     (evaluateGuess(data) === true)
       ? <LazyLoadImage
       className="Svg"
       width="2560" height="1698"
       src="/assets/images/logo_white.svg"
       alt=""
     />
       : <LazyLoadImage
       className="Svg"
       width="2560" height="1698"
       src="/assets/images/logo.svg"
       alt=""
     /> 
  }
</footer>
);

export default Footer;
