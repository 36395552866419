import React from 'react';
// import dayjs from 'dayjs';


function toggleNav() {
    // console.log("hi");
    document.body.classList.toggle("is-state--menu")

}


const Navbar = ({ data }) => (
    <header className="Header js-header ">
        <h1 className="Header-name">
            <a href="https://craçe.house/">
                <svg version="1.0" className='Svg'
                    width="1694.000000pt" height="160pt" viewBox="0 0 1694.000000 200.000000"
                    preserveAspectRatio="xMidYMid meet">
                  
                    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                  <path className="Svg-path" d="M808 1985 c-389 -64 -674 -321 -775 -700 -24 -91 -27 -116 -27 -285
0 -169 3 -194 27 -285 73 -274 245 -490 482 -606 167 -81 348 -117 527 -105
302 19 547 140 713 351 62 78 143 232 129 245 -10 9 -375 142 -379 138 -1 -2
-15 -30 -31 -63 -44 -91 -159 -202 -261 -253 -106 -52 -173 -66 -298 -60 -115
6 -179 27 -276 90 -200 128 -328 366 -304 567 19 166 108 335 251 476 221 219
543 207 779 -30 82 -81 142 -181 160 -262 3 -18 10 -33 13 -33 14 0 384 99
389 104 10 9 -29 123 -72 211 -123 253 -354 428 -649 491 -103 22 -294 26
-398 9z"/>
                        <path className="Svg-path" d="M10435 1989 c-226 -32 -459 -163 -595 -335 -73 -92 -152 -249 -179
-360 -45 -176 -45 -412 0 -588 27 -111 106 -268 179 -360 247 -311 719 -431
1137 -287 238 82 447 279 543 511 61 147 75 227 75 430 0 203 -14 283 -75 430
-96 232 -305 429 -543 511 -157 54 -369 73 -542 48z m367 -383 c168 -30 303
-133 376 -288 53 -110 67 -173 66 -293 0 -82 -5 -114 -28 -180 -62 -182 -187
-319 -371 -411 -235 -116 -462 -60 -705 172 -247 237 -255 534 -19 773 185
188 433 271 681 227z"/>
                        <path className="Svg-path" d="M14318 1985 c-246 -47 -419 -178 -489 -371 -32 -87 -33 -252 -1 -351
44 -142 168 -262 347 -337 48 -20 155 -57 239 -82 200 -60 276 -89 326 -125
112 -82 116 -206 10 -322 -95 -104 -204 -123 -328 -57 -129 68 -231 189 -274
321 -16 52 -26 68 -37 64 -9 -2 -81 -20 -161 -40 -80 -20 -162 -41 -184 -46
l-38 -10 7 -46 c10 -68 57 -193 96 -258 52 -87 162 -186 260 -234 141 -69 216
-84 424 -85 209 -1 291 13 420 73 138 65 231 167 283 313 22 62 26 91 26 193
1 114 -1 123 -28 178 -88 173 -318 327 -761 507 -172 70 -209 92 -231 139 -15
30 -15 37 1 76 19 52 98 128 157 154 125 53 272 22 384 -81 44 -41 96 -133
109 -195 4 -18 10 -33 13 -33 19 0 358 84 365 90 5 4 -1 43 -13 86 -51 188
-165 328 -331 407 -52 25 -123 53 -159 62 -91 23 -331 29 -432 10z"/>
                        <path className="Svg-path" d="M2190 1001 l0 -961 210 0 210 0 0 355 0 355 149 0 149 0 192 -340
c106 -186 197 -346 204 -355 9 -13 43 -15 243 -13 l232 3 -214 365 c-118 201
-211 368 -207 371 4 4 30 14 59 23 156 51 271 179 319 356 23 87 24 288 1 383
-49 201 -179 327 -404 389 -75 21 -99 22 -610 26 l-533 3 0 -960z m996 573
c106 -36 149 -101 148 -224 0 -120 -45 -184 -151 -216 -34 -10 -114 -14 -310
-14 l-263 0 0 235 0 235 265 0 c216 0 273 -3 311 -16z"/>
                        <path className="Svg-path" d="M4240 1013 c-194 -522 -355 -954 -358 -960 -3 -10 45 -13 221 -13
l225 0 42 117 c23 65 53 153 68 195 l27 78 387 0 387 0 11 -42 c6 -24 16 -59
22 -78 15 -49 98 -253 107 -263 4 -4 107 -6 228 -5 l221 3 -357 955 -356 955
-261 3 -260 2 -354 -947z m743 139 c70 -196 127 -361 127 -365 0 -4 -117 -7
-260 -7 -143 0 -260 1 -260 3 0 31 94 457 110 497 33 84 128 230 149 230 4 0
65 -161 134 -358z"/>
                        <path className="Svg-path" d="M6020 1000 l0 -960 700 0 700 0 0 185 0 185 -490 0 -490 0 0 205 0
205 450 0 450 0 0 180 0 180 -450 0 -450 0 0 205 0 205 490 0 490 0 0 185 0
185 -700 0 -700 0 0 -960z"/>
                        <path className="Svg-path" d="M7710 1000 l0 -960 210 0 210 0 0 390 0 390 400 0 400 0 0 -390 0
-390 210 0 210 0 0 960 0 960 -210 0 -210 0 0 -385 0 -385 -400 0 -400 0 0
385 0 385 -210 0 -210 0 0 -960z"/>
                        <path className="Svg-path" d="M11852 1268 c4 -687 4 -694 27 -768 57 -189 166 -321 331 -403 129
-63 231 -87 411 -94 435 -17 722 142 836 462 l28 80 3 708 3 707 -206 0 -205
0 0 -587 c0 -324 -5 -621 -10 -661 -12 -91 -50 -178 -98 -225 -96 -92 -263
-129 -422 -91 -122 28 -210 104 -251 217 -23 62 -23 64 -27 705 l-3 642 -210
0 -210 0 3 -692z"/>
                        <path className="Svg-path" d="M15550 1000 l0 -960 695 0 695 0 0 185 0 185 -485 0 -485 0 0 205 0
205 450 0 450 0 0 180 0 180 -450 0 -450 0 0 205 0 205 485 0 485 0 0 185 0
185 -695 0 -695 0 0 -960z"/>
                    </g>
                </svg>
                CRAEHOUSE
            </a>
        </h1>
        <ul className="Header-menu">
            <li className="Header-menuItem Header-menuItem--about"><a href="/about/">About</a></li>
        </ul>
       
       <ToggleHamburger onButtonClick={toggleNav}/>
    </header>
);


function ToggleHamburger({ onButtonClick }) {
    return (
     <button className="Header-menuToggler js-toggleStateMenu" onClick={onButtonClick}>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--top"></div>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--middle"></div>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--bottom"></div>
     </button>
    );
}

export default Navbar;
